<template>
    <div v-if="isLoading">
        <div class="text-center text-danger screen-center" style="padding-top: 20%;">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-sm-5">
                <div class="breadcrumb">
                    <h1>Nuevo Usuario</h1>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="col-sm-5">
                            <button class="btn th-custom-color" @click="storeUser">
                                Guardar
                            </button>
                            <router-link :to="{ name: 'usuario-configuracion-index' }">
                                <button
                                    style="margin-left: 10px;"
                                    class="btn btn-white border border-light">
                                    Regresar
                                </button>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Empresa:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <select class="custom-select" v-model="empresa">
                                                    <option 
                                                        v-for="(empresa, index2) in empresas" 
                                                        :key="index2"
                                                        :value="empresa.id"
                                                    >
                                                        {{ empresa.numero }} - {{ empresa.nombre_comercial }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Nombre Completo:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="nombreCompleto"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label for="email" class="col-form-label">datoUsuaurio/Mail:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="mail"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Contraseña:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input
                                                    type="password"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="password"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 pr-0 text-right">
                                                <input
                                                    style="margin-top:10px;"
                                                    type="checkbox"
                                                    v-model="cambiarPassword"
                                                >
                                            </div>
                                            <div class="col-sm-7">
                                                <label class="col-form-label">Cambiar contraseña al ingresar:</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Celular:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="celular"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Foto:</label>
                                            </div>
                                            <div class="col-sm-5">
                                                <!-- <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                > -->
                                                <label :class=" archivo == '' || archivo == null ? 
                                                'btn btn-warning col-form-label' : 'btn th-custom-color text-white col-form-label'">
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg,image/png"
                                                        hidden
                                                        id="foto"
                                                        name="foto"
                                                        class="form-control-file"
                                                        ref="file"
                                                        v-on:change="obtenerImagen($event)"
                                                    />Subir
                                                </label>
                                                <label class="col-form-label" @click="verImagen" style="padding-left: 10px">Ver:</label>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Estado:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <button class="btn" :class="estado ? 'th-custom-color' : 'btn-warning'" @click="estado=!estado">Activo</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="col-sm-1 text-right">
                                                    <label for="privilegios">Privilegios</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="row mb-2">
                                                    <div class="col-sm-2 pr-0 text-right">
                                                        <label style="white-space: nowrap;" class="col-form-label">Tipo de Usuario:</label>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <multiselect
                                                            v-model="usuarioSelected"
                                                            :options="usuarios"
                                                            track-by="id"
                                                            label="descripcion"
                                                            placeholder="Seleccionar"
                                                            deselectLabel=""
                                                            selectLabel="Seleccionar"
                                                            :searchable="true">
                                                        </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="margin-top: auto;">
                                    </div>

                                        <div class="col-sm-6">
                                            <div class="col-sm-7">
                                                <center>
                                                <button class="btn btn-success">ADMIN</button>
                                                </center>
                                            </div>
                                            <div class="row mb-2">
                                               
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Nuevo</th>
                                                            <th>Modificar</th>
                                                            <th>Anular</th>
                                                            <th>Listar</th>
                                                            <th>Ver</th>
                                                            <th>Imprimir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Dashboard 1</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Dashboard 2</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Configuracion</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Paquete</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Registros</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Empresas</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="col-sm-7">
                                                <center>
                                                <button class="btn btn-default">Cliente</button>
                                                </center>
                                            </div>
                                            <div class="row mb-2">
                                                <table class="table">
                                                    <thead>
                                                        <th></th>
                                                        <th>Nuevo</th>
                                                        <th>Modificar</th>
                                                        <th>Anular</th>
                                                        <th>Listar</th>
                                                        <th>Ver</th>
                                                        <th>Imprimir</th>
                                                    </thead>
                                                    <tbody>
                                                         <tr>
                                                            <th scope="row">Dashboard:</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Usuarios:</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Facturacion:</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Datos Empresa:</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Cliente:</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Sincronizacion SIAT:</th>
                                                            <td><input type="radio" name="nuevo" id=""></td>
                                                            <td><input type="radio" name="modificar" id=""> </td>
                                                            <td><input type="radio" name="anular" id=""></td>
                                                            <td><input type="radio" name="listar" id=""></td>
                                                            <td><input type="radio" name="ver" id=""></td>
                                                            <td><input type="radio" name="imprimir" id=""></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                
                                            </div>
                                                <div class="row mb-2">
                                                    <div class="col-sm-2 pr-0 text-right">
                                                        <label style="white-space: nowrap;" class="col-form-label">Pais:</label>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <multiselect
                                                            v-model="paisSelected"
                                                            :options="paises"
                                                            track-by="id"
                                                            label="descripcion" 
                                                            placeholder="Seleccionar"
                                                            deselectLabel=""
                                                            selectLabel="Seleccionar"
                                                            :searchable="true">
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-sm-2 pr-0 text-right">
                                                        <label style="white-space: nowrap;" class="col-form-label">Cliente:</label>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <multiselect
                                                            v-model="clienteSelected"
                                                            :options="clientes"
                                                            track-by="id"
                                                            label="descripcion"
                                                            placeholder="Seleccionar"
                                                            deselectLabel=""
                                                            selectLabel="Seleccionar"
                                                            :searchable="true">
                                                        </multiselect>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, call, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect'


export default {
    name: "create",
    components: {
          Multiselect,
    },
    data() {
        return {
            isLoading: false,
            urlImage: null,
        };
    },
    mounted() {
        this.create()
    },
    methods: {
        ...call('datoUsuaurio/usuarioCreate', ['store','create']),
        async storeUser() {
            this.isLoading = true;
            const STORE_OK = await this.store();
            if (STORE_OK) {
                this.$router.push({ name: 'usuario-configuracion-index' });
            }
            this.isLoading = false;
        },
        obtenerImagen(event) {
            this.archivo = event.target.files[0];
            this.urlImage = URL.createObjectURL(this.archivo);
        },
        verImagen() {
            if (this.urlImage) {
                window.open(this.urlImage);
            }
        }
    },
    computed: {
        ...get('datoUsuaurio/usuarioCreate', [
            'usuarios',
            'paises',
            'clientes',
            'empresas'
        ]),
        ...sync('datoUsuaurio/usuarioCreate', [
            'nombreCompleto',
            'mail',
            'password',
            'cambiarPassword',
             'usuarioSelected',
            'paisSelected',
            'clienteSelected',
            'celular',
            'estado',
            'archivo',
            'empresa',
        ]),
    },
    beforeDestroy() {
        this.$store.dispatch('datoUsuaurio/usuarioCreate/reset');
    }
}
</script>